<template>
  <div class="box">
    <div class="bank-box">
      <van-grid icon-size="25px" :border="false" column-num="5">
        <van-grid-item
          :icon="'https://img.weiqilile.com/bank/thumb/' + b.code + '.png'"
          :text="b.name"
          v-for="b in bankList"
          :key="b.name"
        />
      </van-grid>
    </div>
  </div>
</template>

<script>
import url from "@/service/url-config";
export default {
  data() {
    return {
      bankList: [],
    };
  },
  created() {
    this.getBankList();
  },
  methods: {
    //获取银行列表
    getBankList() {
      this.$http.get(url.bankList).then((res) => {
        this.bankList = res.data.content;
      });
    },
  },
};
</script>

<style lang="less" scoped>
.box {
  background: #ffffff;
  min-height: calc(100vh - 60px);
  padding-top: 60px;
  .address-box {
    height: calc(100% - 40px);
    padding-top: 40px;
    .address-title {
      width: 345px;
      margin: 0 auto;
      margin-bottom: 10px;
      color: #999999;
      font-size: 14px;
    }
    .location-box {
      width: 345px;
      margin: 0 auto;
      margin-bottom: 20px;
      display: flex;
      justify-content: space-between;
      .location-name {
        color: #333333;
        font-size: 16px;
      }
      .location-button {
        font-size: 14px;
        color: #1377ff;
      }
    }
  }
  .address-box /deep/.van-cascader__options {
    height: 250px;
  }
  .banner {
    width: 100%;
    margin: 0 auto;
    overflow-x: scroll;
    display: flex;
    img {
      width: 200px;
      height: 100px;
      margin-left: 12px;
      border-radius: 10px;
    }
  }
  .hint {
    width: 355px;
    height: 40px;
    margin: 0 auto;
    border-radius: 10px;
    margin-top: 10px;
    background: #f5f5f5;
    overflow: hidden;
    display: flex;
    align-items: center;
  }
  .hint-text {
    font-size: 12px;
    color: #666666;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .gonggao {
    flex: none;
    width: calc(355px - 50px);
    height: 40px;
    line-height: 40px;
  }
  .hint-icon {
    width: 59px;
    margin-right: 10px;
    margin-left: 10px;
  }
  .grid {
    width: 355px;
    margin: 0 auto;
    border-radius: 10px;
  }
  .grid .van-grid-item__text text {
    font-size: 12px;
    color: #333333;
  }
  .tag-box {
    width: 355px;
    height: 130px;
    margin: 0 auto;
    margin-top: 20px;
    background: #ffffff;
    border-radius: 10px;
    display: flex;
    justify-content: space-around;
    align-items: center;
  }
  .tag {
    width: 80px;
    text-align: center;
  }
  .tag-icon {
    width: 34px;
    height: 34px;
  }
  .tag-title {
    font-size: 15px;
    color: #51608d;
  }
  .tag-hint {
    font-size: 10px;
    color: #8b94b3;
  }
  .product-box {
    width: 355px;
    background: #ffffff;
    margin: 0 auto;
    border-radius: 10px;
  }
  .cell-title {
    font-size: 16px;
    color: #000000;
    font-weight: bold;
  }
  .cell-val {
    font-size: 12px;
    color: #8f98b3;
  }
  .product {
    width: calc(350px - 16px);
    height: calc(105px - 15px);
    padding: 17px 10px;
    background: #f4f7ff;
    border-radius: 10px;
    margin-bottom: 10px;
  }
  .product-top {
    padding-bottom: 20px;
    font-size: 12px;
    color: #000000;
    font-weight: bold;
  }
  .price {
    font-size: 22px;
    color: #f55c00;
    font-weight: bold;
    width: 100%;
    text-align: center;
    padding-bottom: 2px;
  }
  .rate {
    font-size: 20px;
    color: #f55c00;
    font-weight: bold;
    width: 100%;
    text-align: center;
    padding-top: 4px;
    padding-bottom: 5px;
  }
  .mode {
    font-size: 14px;
    color: #4f5e89;
    width: 100%;
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding-bottom: 10px;
    padding-top: 4px;
  }
  .product-name {
    width: 100%;
    text-align: center;
    font-size: 12px;
    color: #888888;
  }
  .product-button {
    // width: 75px;
    height: 30px;
    color: 13px;
  }

  .bank-box {
    width: 355px;
    background: #ffffff;
    border-radius: 10px;
    margin: 0 auto;
    margin-top: 20px;
  }
  .bank-title {
    font-size: 16px;
    color: #000000;
    font-weight: bold;
    padding: 10px;
    span {
      color: #999999;
      font-size: 11px;
      font-weight: normal;
    }
  }
  .bank-box /deep/ .van-grid-item__content {
    padding-left: 0;
    padding-right: 0;
  }
  .bank-box /deep/ .van-grid-item__text {
    font-size: 10px;
    color: #808aab;
    margin-top: 10px;
  }
  .kecheng {
    width: 350px;
    height: 125px;
    border-radius: 10px;
  }
  .wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }
  .report-img {
    width: 263px;
    height: 334px;
  }
  .report-button {
    width: 100px;
    height: 30px;
    background: #ffffff;
    border-radius: 15px;
    color: #ff6103;
    font-size: 12px;
    text-align: center;
    line-height: 30px;
    position: absolute;
    left: 50%;
    margin-left: -50px;
    bottom: 27px;
  }
  .close {
    width: 25px;
    height: 25px;
    position: absolute;
    bottom: -30px;
    left: 50%;
    margin-left: -17.5px;
  }
}
</style>
